import React from "react"
import { styled } from "linaria/react"
import Button from "./Button"
import Img from "gatsby-image"
import Link from "gatsby-link"

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #c1c1c1;
    text-transform: uppercase;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    color: #000000;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    /* or 150% */
    color: #7d7d7d;
    margin-bottom: 50px;
  }
`

const StyledImage = styled(Img)`
  @media screen and (max-width: 900px) {
    height: auto;
    max-height: 420px;
  }
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  position: relative;
  height: 0;
  padding: 56.25% 0 0 0;
  img {
    object-fit: cover;
    object-position: center;
  }
`

const Divider = styled.div`
  height: 1px;
  background: #000;
  width: 90px;
  margin-bottom: 10px;
`
const BasicPost = props => {
  const post = props.post
  return (
    <Wrapper>
      <Link to={`/blog/${post.slug}/`}>
        <StyledImage
          fluid={post.featuredImage.fluid}
          imgStyle={{ objectFit: "", objectPosition: "" }}
        />
      </Link>
      <span>{post.category}</span>
      <Link to={`/blog/${post.slug}/`}>
        <h3>{post.tItle}</h3>
      </Link>
      <Divider />
      <p>{post.summary}</p>
      <Button text={"Read More"} link={`/blog/${post.slug}/`} />
    </Wrapper>
  )
}

export default BasicPost
