import React from "react"
import { styled } from "linaria/react"
import Img from "gatsby-background-image"
import Link from "gatsby-link"

const BackgroundImg = styled(Img)`
  width: 100%;
  min-height: 700px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
  @media screen and (max-width: 480px) {
    min-height: 70vh;
  }
`

const Content = styled.div`
  max-width: 40%;
  margin-top: auto;

  color: #fff;
  text-transform: uppercase;

  > span {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 20px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  h1 {
    margin-top: 5px;
    margin-bottom: 0;

    line-height: 50px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  a {
    color: #fff;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    max-width: 60%;
  }
  @media screen and (max-width: 768px) {
    max-width: 80%;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    h1 {
      font-size: 30px;
      font-weight: 500;
      line-height: 36px;
    }
  }
`

const StyledLink = styled(Link)`
  margin-top: 25px;
`
const Hero = props => {
  const background = [
    props.fluid,
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 30.1%, #000000 100%)`,
  ].reverse()
  return (
    <BackgroundImg fluid={background}>
      <Content>
        <span>Featured Article</span>
        <Link to={props.link}>
          <h1>{props.title}</h1>
        </Link>
      </Content>
    </BackgroundImg>
  )
}

export default Hero
