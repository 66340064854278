import React from "react"
import { styled } from "linaria/react"
import BasicPost from "../Elements/BasicPost"

const Wrapper = styled.section`
  @media screen and (max-width: 480px) {
    margin-bottom: 100px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 250px;
`

const PostWrapper = styled.div`
  @media screen and (max-width: 1280px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    padding: 0 15px;
  }
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 55px;
  grid-row-gap: 55px;
  padding: 0 55px;
`

const SectionHeader = styled.div`
  @media screen and (max-width: 1280px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 15px;
  }
  display: flex;
  padding: 0 55px;
  align-items: center;
  > span {
    font-size: 16px;
    line-height: 19px;
    color: #cccccc;
    text-transform: uppercase;
    font-weight: 300;
    min-width: 124px;
    margin-right: 15px;
  }
`

const Line = styled.div`
  height: 1px;
  background: #ccc;
  width: 100%;
  margin-left: auto;
`

const RecentPosts = props => {
  const postsData = props.posts
  const posts = postsData.map((post, index) => {
    return <BasicPost key={index} post={post.node} />
  })
  return (
    <Wrapper>
      <SectionHeader>
        <span>Recent Posts</span>
        <Line />
      </SectionHeader>
      <PostWrapper>{posts}</PostWrapper>
    </Wrapper>
  )
}

export default RecentPosts
