import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Hero from "../components/Sections/Blog/Hero"
import RecentPosts from "../components/Sections/RecentPosts"
import Social from "../components/Sections/SocialFeed"
import Subscription from "../components/Sections/Subscription"

import { AllPosts } from "../Hooks/BlogData"

const Blog = () => {
  const { edges } = AllPosts()
  const recentPosts = edges
  const getFeaturedPost = blogs => {
    let i
    for (i = 0; i < edges.length; i++) {
      if (blogs[i].node.featured === true) {
        return blogs[i].node
      }
    }
  }
  const featuredPost = getFeaturedPost(recentPosts)
  return (
    <Layout>
      <SEO
        title="Blog"
        description="Fashion, Adventure, Recipes, Beauty and everything in between. Sharing my stories and adventures in life."
      />
      <Hero
        fluid={featuredPost.featuredImage.fluid}
        category={featuredPost.category}
        title={featuredPost.tItle}
        link={`/blog/${featuredPost.slug}/`}
      />
      <RecentPosts posts={recentPosts} />
      <Subscription />
      <Social />
    </Layout>
  )
}

export default Blog
